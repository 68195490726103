import { INews } from './../types/IJson';
import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import JSonService from '../services/JSonService';
import { Banner } from '../models/Banner';
import { API } from '../configs/APIEndPoint';
import { FeedbackCategory } from '../models/FeedbackCategory';

class JSonStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable listBanner: Array<Banner> | null = null;
    @observable listFeedbackCategory: Array<FeedbackCategory> | null = null;

    //action
    @action getAppBanner = async () => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.BANNER_LIST
        )) as Array<Banner>;
        if (result) {
            runInAction(() => {
                this.listBanner = result;
            });
        }
    };

    @action getListFeedbackCategory = async () => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.FEEDBACK_CATEGORY
        )) as Array<FeedbackCategory>;
        if (result) {
            runInAction(() => {
                this.listFeedbackCategory = result;
            });
        }
    };
}
export default JSonStore;
