import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import {
    FeedbackViewContainer,
    FeedbackViewWrapper
} from './FeedbackView.styles';

//components
import { FeedbackItem } from './FeedbackItem/FeedbackItem';
import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

//styles

interface Props {}

export const FeedbackView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listFeedbackCategory, getListFeedbackCategory } = store.jsonStore;

    useEffect(() => {
        getListFeedbackCategory();
    }, []);

    // return (
    //     <FeedbackViewWrapper>
    //         <FeedbackViewContainer>
    //             {listFeedbackCategory ? (
    //                 <Row>
    //                     <FeedbackItem
    //                         data={listFeedbackCategory[0]}
    //                         key={listFeedbackCategory[0].id}
    //                     />
    //                     <FeedbackItem
    //                         data={listFeedbackCategory[1]}
    //                         key={listFeedbackCategory[1].id}
    //                     />
    //                     <FeedbackItem
    //                         data={listFeedbackCategory[2]}
    //                         key={listFeedbackCategory[2].id}
    //                     />
    //                     <FeedbackItem
    //                         data={listFeedbackCategory[3]}
    //                         key={listFeedbackCategory[3].id}
    //                     />
    //                     <FeedbackItem
    //                         data={listFeedbackCategory[4]}
    //                         key={listFeedbackCategory[4].id}
    //                     />
    //                 </Row>
    //             ) : null}
    //         </FeedbackViewContainer>
    //     </FeedbackViewWrapper>
    // );

    return (
        <FeedbackViewWrapper>
            <FeedbackViewContainer>
                <Row>
                    {listFeedbackCategory &&
                        listFeedbackCategory.map((item) => {
                            return <FeedbackItem data={item} key={item.id} />;
                        })}
                </Row>
            </FeedbackViewContainer>
        </FeedbackViewWrapper>
    );
});
