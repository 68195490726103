import { Route, Routes, Navigate } from 'react-router-dom';

//import views
import { HomePage } from '../views/HomePage/HomePage';
//import layouts
import MainLayout from '../layouts/MainLayout/MainLayout';

//protected router with private resources must be login
import ProtectedRoute from '../components/ProtectedRoute';

const AppRouters = () => {
    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AppRouters;
