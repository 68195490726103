import { Col } from 'antd';
import styled, { keyframes } from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

const rotate = keyframes`
  0%{
      transform: scale(1.5);
      opacity: 0;
    }
    100%{
      transform: scale(1);
      opacity: 1;
    }
`;

export const FeedbackItemWrapper = styled.div`
    margin: 20px;
    cursor: pointer;
`;

export const ItemImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 15px;
    /* background-image: linear-gradient(
        to right top,
        #fff,
        #008793,
        #00bf72,
        #a8eb12
    ); */

    /* background-image: linear-gradient(
        45deg,
        hsl(240deg 100% 20%) 0%,
        hsl(289deg 100% 21%) 11%,
        hsl(315deg 100% 27%) 22%,
        hsl(329deg 100% 36%) 33%,
        hsl(337deg 100% 43%) 44%,
        hsl(357deg 91% 59%) 56%,
        hsl(17deg 100% 59%) 67%,
        hsl(34deg 100% 53%) 78%,
        hsl(45deg 100% 50%) 89%,
        hsl(55deg 100% 50%) 100%
    ); */
`;

export const ItemImage = styled.img`
    //width: 150px;
    height: 100px;
    object-fit: cover;
`;

export const FeedbackItemContainer = styled.div`
    background-color: #fff;
    //width: 100%;
    height: 240px;
    border-radius: 15px;
    //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    :hover {
        //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
            rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    :hover ${ItemImage} {
        transform: scale(1.1);
        //animation: ${rotate} 200ms linear;
        transition-duration: 200ms;
    }
`;

export const ItemInfoWrapper = styled.div`
    padding: 15px;
`;

export const ItemHeader = styled.h1`
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: #403d3d;
    //font-family: 'Sans-serif';
`;

export const ItemDescription = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #918d8d;
`;

export const FeedbackCol = styled(Col)`
    @media ${device.mobileS} {
        flex-basis: 50%;
        width: 50%;
    }
    @media ${device.tablet} {
        flex-basis: 25%;
        width: 25%;
    }
    @media ${device.laptop} {
        flex-basis: 20%;
        width: 20%;
    }
`;
