import { ComponentType } from 'react';
import pure from 'recompose/pure';
import {
    FeedbackCol,
    FeedbackItemContainer,
    FeedbackItemWrapper,
    ItemDescription,
    ItemHeader,
    ItemImage,
    ItemImageWrapper,
    ItemInfoWrapper
} from './FeedbackItem.styles';
import { Col } from 'antd';
import { FeedbackCategory } from '../../../../models/FeedbackCategory';
import { observer } from 'mobx-react-lite';

//components

//styles

interface Props {
    data: FeedbackCategory;
}

export const FeedbackItem: ComponentType<Props> = observer(({ data }) => {
    return (
        <FeedbackCol>
            <FeedbackItemWrapper>
                <FeedbackItemContainer>
                    <ItemImageWrapper>
                        <ItemImage src={data.image} />
                    </ItemImageWrapper>
                    <ItemInfoWrapper>
                        <ItemHeader>{data.title}</ItemHeader>
                        <ItemDescription>{data.description}</ItemDescription>
                    </ItemInfoWrapper>
                </FeedbackItemContainer>
            </FeedbackItemWrapper>
        </FeedbackCol>
    );
});
