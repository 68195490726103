import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const BannerViewWrapper = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    border-radius: 15px;
    position: sticky;
    top: 20px;
    z-index: 1000;
    max-width: 1200px;
    margin: 0px auto;
    margin-bottom: 40px;
`;

export const BannerViewContainer = styled.div``;

export const SwiperSlideImage = styled.img`
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
    @media ${device.mobileS} {
        height: 200px;
    }
    @media ${device.laptop} {
        height: 200px;
    }
`;
