import React, { ComponentType, FC, ReactNode, useState } from 'react';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Outlet } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FormOutlined, PlusOutlined } from '@ant-design/icons';
import { useRootStore } from '../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
// define interface to represent component props
interface Props {}

const MainLayout: ComponentType<Props> = observer(() => {
    const store = useRootStore();
    const { isDialogAddTicketActive } = store.globalStore;

    const [showAddTicket, setShowAddTicket] = useState(false);

    const onRequestAddTicket = async () => {
        if (isDialogAddTicketActive == false) setShowAddTicket(true);
    };

    return (
        <div>
            <Header></Header>
            <Outlet />
            <Fab
                alwaysShowTitle={false}
                text="Thêm yêu cầu hỗ trợ"
                onClick={() => onRequestAddTicket()}
                mainButtonStyles={{
                    backgroundColor: '#eb4034',
                    cursor: 'pointer'
                }}
                icon={<PlusOutlined />}></Fab>
            <Footer />
        </div>
    );
});

export default MainLayout;
