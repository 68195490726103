export const API = {
    AUTHENTICATION: {},
    USER: {
        PROFILE_DETAIL: 'user-detail',
        FEEDBACK: 'feedback',
        ADD_GAME: 'request-game'
    },

    JSON: {
        BANNER_LIST: 'json/banner.json',
        FEEDBACK_CATEGORY: 'json/feedback_category.json'
    }
};
