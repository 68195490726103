import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import * as AxiosLogger from 'axios-logger';
import { HTTP_STATUS } from '../configs/HttpStatus';
import { LOCAL_KEY } from '../configs/AppKey';
import { error } from 'console';
import { API } from '../configs/APIEndPoint';

//create a instance of axios
const APIHelper = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-type': 'application/json'
    },
    timeout: Number(process.env.REACT_APP_TIMEOUT_API)
});

export const JSonHelper = axios.create({
    baseURL: process.env.REACT_APP_CDN_URL,
    headers: {
        'Content-type': 'application/json'
    },
    timeout: Number(process.env.REACT_APP_TIMEOUT_API)
});

//handle request and response interceptors
APIHelper.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        config.data.meta = config.data.meta || {};
        config.data.meta.requestStartedAt = new Date().getTime();

        return AxiosLogger.requestLogger(config);
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

APIHelper.interceptors.response.use(
    (response: AxiosResponse) => {
        const metaData = JSON.parse(response.config.data);

        console.log(
            `Execution time for: ${response.config.url} - ${
                new Date().getTime() - metaData.meta.requestStartedAt
            } ms`
        );
        return AxiosLogger.responseLogger(response);
    },
    (error: AxiosError) => {
        // Log the error
        console.error(
            'APIHelper.interceptors.response :' + JSON.stringify(error)
        );

        //Check if the response is having error code as 401 then return login
        if (forceLogin(error)) {
            localStorage.removeItem(LOCAL_KEY.CURRENT_USER);
            window.location.href = '/?login=1';
        }

        if (isMaintenance(error)) {
            window.location.href = '/maintenance';
        }

        //check if api user details error
        if (error.config.url?.indexOf(API.USER.PROFILE_DETAIL)! != -1) {
            localStorage.removeItem(LOCAL_KEY.CURRENT_USER);
            window.location.href = '/?login=1';
        }

        return Promise.reject(error);
    }
);

const isMaintenance = (error: AxiosError) => {
    return error.response?.status === HTTP_STATUS.MAINTENANCE;
};

const forceLogin = (error: AxiosError) => {
    return (
        error.response?.status === HTTP_STATUS.UNAUTHORIZED ||
        error.response?.status === HTTP_STATUS.TOKEN_INVALID
    );
};

//handles response and error from server
export const handleResponse = (response: AxiosResponse) => {
    return response.data;
};

export const handleError = (error: AxiosError) => {
    console.error(error);
    AxiosLogger.errorLogger(error);
};

export default APIHelper;
