import GlobalStore from './GlobalStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import JSonStore from './JSonStore';

class RootStore {
    globalStore: GlobalStore;
    userStore: UserStore;
    authStore: AuthStore;
    jsonStore: JSonStore;

    constructor() {
        this.globalStore = new GlobalStore(this);
        this.userStore = new UserStore(this);
        this.authStore = new AuthStore(this);
        this.jsonStore = new JSonStore(this);
    }
}
export default RootStore;
