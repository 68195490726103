import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { EffectFlip, EffectFade, Navigation, Pagination } from 'swiper';

import { useRootStore } from '../../../providers/RootStoreProvider';
import {
    BannerViewContainer,
    BannerViewWrapper,
    SwiperSlideImage
} from './BannerView.styles';
import { Banner } from '../../../models/Banner';

//components

//styles

interface Props {}

export const BannerView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listBanner, getAppBanner } = store.jsonStore;

    useEffect(() => {
        SwiperCore.use([Autoplay]);
        getAppBanner();
    }, []);

    const onRenderLargeBanner = (banner: Banner) => {
        return (
            <a href={banner.url} target="_blank">
                <SwiperSlideImage src={banner.bannerImage} />
            </a>
        );
    };

    return (
        <BannerViewWrapper>
            <BannerViewContainer>
                <Swiper
                    key={Date.now()}
                    spaceBetween={0}
                    effect={'flip'}
                    navigation={true}
                    autoplay={true}
                    pagination={{
                        clickable: true
                    }}
                    modules={[EffectFade, Navigation, Pagination]}
                    className="mySwiper">
                    {listBanner &&
                        listBanner.map((item) => {
                            return (
                                <SwiperSlide key={item.bannerId}>
                                    {onRenderLargeBanner(item)}
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </BannerViewContainer>
        </BannerViewWrapper>
    );
});
