import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { HomePageContainer, HomePageWrapper } from './HomePage.styles';

//components
import { BannerView } from './BannerView/BannerView';
import { FeedbackView } from './FeedbackView/FeedbackView';
import { ToolbarView } from './ToolbarView/ToolbarView';
import { observer } from 'mobx-react-lite';

//styles

interface Props {}

export const HomePage: ComponentType<Props> = observer(({}) => {
    return (
        <HomePageWrapper>
            <HomePageContainer>
                {/* <ToolbarView /> */}
                <BannerView />
                <FeedbackView />
            </HomePageContainer>
        </HomePageWrapper>
    );
});
